<template>
  <router-view v-if="loadingComplete && error == null"></router-view>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PROJECT_INFO } from "@/core/services/store/project/project.module";
import { UPDATE_CLIENT_INFO } from "@/core/services/store/core/client.module";
import { ADD_MENU_TYPE } from "@/core/services/store/system/menu.module";

export default {
  name: "Project",

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 3
      },
      error: null
    };
  },

  mounted() {
    this.preloadClient();
    this.setMenuType();
  },

  methods: {
    preloadClient() {
      let payload = {
        clientId: this.clientIdentifier,
        domain: this.projectIdentifier
      };
      this.$store
        .dispatch(UPDATE_CLIENT_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
          this.preloadProject();
        })
        .catch(() => {
          this.$router.push({ name: "404" });
        });
    },
    preloadProject() {
      let payload = {
        clientIdentifier: this.clientInfo.id,
        projectIdentifier: this.projectIdentifier,
        params: {
          domain: this.projectIdentifier,
          projectTemplate: "with"
        }
      };
      this.$store
        .dispatch(UPDATE_PROJECT_INFO, payload)
        .then((res) => {
          if (res.system_project_stage_id == 6) {
            this.error = "This project has been erased and can no longer be accessed";
            window.alert(this.error);
          } else if (res.isTemplate == 1 && res.project_template.system_project_template_type_id == 1 && this.currentUser.system_admin_role_id != 1) {
            this.error = "You do not have to correct permissions to edit this project template";
            window.alert(this.error);
          } else {
            this.pageLoader.componentsCompleted++;
          }
        })
        .catch(() => {
          this.$router.push({ name: "404" });
        });
    },
    setMenuType() {
      this.$store.dispatch(ADD_MENU_TYPE, "project").then(() => {
        this.pageLoader.componentsCompleted++;
      });
    }
  },

  computed: {
    ...mapGetters(["currentUser","clientInfo"]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    },
    projectIdentifier: function() {
      let subDomain = window.location.host.split('.')[0];

      if (window.location.host.includes(process.env.VUE_APP_API_ROOT)) {
        return subDomain;
      } else {
        return subDomain.split('-')[0];        
      }
    },
    clientIdentifier: function() {
      if (window.location.host.includes(process.env.VUE_APP_API_ROOT)) {
        let subDomain = window.location.host.split('.')[0];
        return subDomain.split('-')[1];
      } else {
        return document.domain.split('.').reverse().splice(0,2).reverse().join('.');
      }
    }
  }
};
</script>
